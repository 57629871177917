import React from "react";
import VoucherRedemption from "../../components/voucher";

const PARTNER = "LIBRARY_USER";

function Code() {
    return <VoucherRedemption partner={PARTNER} />;
}

export default Code;
